:root{
    --bgColor : #1e1925;
  }


/*modal*/

.modal select {
    border: 1px solid black;
}

.modal{
    position: fixed;
    margin: 0;
    padding: 0px;
    top: 0px;
    min-height: 100vh;
    max-height: fit-content;
    width: 100vw;
    flex-wrap: wrap;
    transition: 1000ms;
    z-index: 2000;
    /* background-color: rgba(255, 255, 255, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;

}

.booking_form{
    padding: 40px;
    background-color: white;
    width: 90vw;
    max-width: 450px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius:10px;
    display: grid;
    gap: 10px;
}

.booking_from input{
    box-sizing: border-box;
    padding: 10px 5px;
}

.form{
    padding: 10px;
    letter-spacing: 1px;
}

.name_input{
    grid-column: 1;
    grid-row: 2;
    
}

.lastname_input{
    grid-column: 2;
    grid-row: 2;
}

.phone_input{
    grid-column: 1 /span 2;
    grid-row:3 ;
}

.email_input{
    grid-column: 1 /span 2 ;
    grid-row: 4;
}
.number_input{
    grid-column: 1/span 2;
    grid-row: 6;
}
.country_input{

    grid-column: 1 /span 2 ;
    grid-row: 5;
}
.package_input{
    grid-column: 1/ span 2;
    grid-row: 8;
}
.room_input {
    grid-column: 1/span 2;
    grid-row : 9
}
.book_btn{
    grid-column: 1/span 2;
    grid-row:10;
    cursor: pointer;
    background-color: var(--bgColor);
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.horizontal-line {
    border-top: 1px solid black;
    margin: 10px 10px;
    width: 60px;
    
}

.or_section{
    display: flex;
    justify-content: center;
    grid-column: 1 /span 2;
    grid-row: 11;
}

.booking_form p{
    text-align: center;
    grid-column: 1 /span 2;
    letter-spacing: 1px;
}

.close{
    text-align: right;
    grid-column: 1 / span 2;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: 300;
}

.subject_inut{
    grid-column: 1 /span 2;
    grid-row: 4;
}

.message_input{
    grid-column: 1 /span 2;
    
}

.send_btn{
    grid-column: 1/span 2;
    cursor: pointer;
    background-color: var(--bgColor);
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.booking_form input,
.booking_form select {
    width: 100%;  /* Ensure it fits within the container */
    box-sizing: border-box; /* Include padding/border in width */
    padding: 10px 5px;
    border: 1px solid black;
    border-radius: 5px;
}


@media only screen and (max-width : 480px){
    .modal{
        
    }
    .booking_form{
        height: 100vh;
        width: fit-content;
        padding: 20px 40px ;
        
    }
    .booking_from input{
        box-sizing: border-box;
        border: 1px solid black;

    }
    
    .book_btn{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .form{
        padding: 5px;

        
    }
    input[type="date"] {
        padding: 10px;
        border: 1px solid gray;
        border-radius: 5px;
        font-size: 16px;
      }
      
}